import React from 'react';
import '@lottiefiles/lottie-player';
import { create } from '@lottiefiles/lottie-interactivity';

class Intro extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // 1. create a reference for the lottie player
  }
  componentDidMount() {
    // 3. listen for player load. see lottie player repo for other events
    this.myRef.current.addEventListener('load', function (e) {
      // 4. configure the interactivity library
      create({
        mode:"",
    player: "#secondLottie",
    
    actions: [
        {
            visibility:[0,1],
            type: "loop",
            
            frames: [0, 120]
        }
    ]
});
    });
  }
  render() {
    return (
      <div className="Animation">
        <div style={{ height: '5    0px' }}></div>
        <lottie-player
          ref={this.myRef} // 2. set the reference for the player
          id="secondLottie"
          loop = "true"
          autoplay = "true"
          mode="normal"
          src="https://assets3.lottiefiles.com/packages/lf20_7civt6in.json"
          style={{ width: '100%' }}
        ></lottie-player>

      </div>
      
    );
  }
}

export default Intro;