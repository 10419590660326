import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Intro from "./componets/Animations/Intro";
import 'bootstrap/dist/css/bootstrap.min.css';




//import App from './App';
import './App.css';
import './index.css'
const App = React.lazy(() => import("./App"));

ReactDOM.render(
  <div>
    <Suspense fallback={<Intro/>}>
      <App />
    </Suspense>
  </div>,
  document.getElementById('root')
);

